import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as GatsbyLink, Script, graphql } from 'gatsby';

import Link from '/src/components/LocalizedLink';
import Layout from '../components/Layout';
import ForthcomingCourses from '../components/ForthcomingCourses';
import ReviewsSection from '../components/ReviewsSection';
import SubscriptionForm from '../components/SubscriptionForm';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../assets/images/svg/arrow-right-red.inline.svg';
import img1 from '../assets/images/home-page/partners/Trinity logo no background.png';
import img2 from '../assets/images/home-page/partners/A-logo.png';
import img3 from '../assets/images/home-page/partners/I-logo.png';
import img4 from '../assets/images/home-page/partners/CSLB-logo-400x126.png';
// import img5 from '../assets/images/home-page/partners/Languages4Life logo.png';
import imgBenefit1 from '../assets/images/home-page/help-s/Internationally Recognized Qualification.svg';
import imgBenefit2 from '../assets/images/home-page/help-s/Classroom Confidence Building.svg'; //
import imgBenefit3 from '../assets/images/home-page/help-s/Post-Grad Job Prospects.svg';
import imgBenefit4 from '../assets/images/home-page/help-s/Varied Learning Styles.svg';
import imgBenefit5 from '../assets/images/home-page/help-s/Small Group Sizes .svg';
import imgDots from '../assets/images/home-page/Dots.png';
import imgDots2 from '../assets/images/home-page/Dots 2.svg';
import imgPhoto_r from '../assets/images/home-page/Photo_r.png';
import imgPhoto_l from '../assets/images/home-page/Photo_l.png';
import imgAccomodation from '../assets/images/Accommodation.svg';
import imgStudentVisas from '../assets/images/Student visas.svg';
import imgCareersAdvice from '../assets/images/Careers advice.svg';
// import fileTEFLIberiaBrochure from '../assets/files/TEFL Iberia brochure web.pdf';

import '../assets/styles/pages/home-page.scss';

const IndexPage = ({ data }) => {
  const allPrismicArticle = data.allPrismicArticle.edges;
  const prismicHomePage = data.prismicHomePage.data;

  return (
    <Layout title={{ id: 'homepage.seo.title' }} description={{ id: 'homepage.seo.description' }}>
      <div className="home-page">
        <section className="c-first-section c-first-section--lg">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 col-xl-7">
                <h1 className="c-first-section__title">
                  <FormattedMessage id="homepage.sec1.title" />
                </h1>
                <p className="c-first-section__description">
                  <FormattedMessage id="homepage.sec1.description" />
                </p>
                <div className="c-first-section__btns">
                  <FormattedMessage id="url.courses.one-month-160-hour-tefl-course.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        {/* <FormattedMessage id="menu.applyNow" /> */}
                        Find Out More
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                  {/* <Link to="/covid/" className="c-btn c-btn--custom">
                    Coronavirus FAQs
                  </Link> */}
                  {/* <Link href="/covid/" className="c-btn c-btn--custom">
                    FAQ: Coronavirus & TEFLs
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section">
          <div className="container">
            <div className="second-section__wrapper">
              <div className="c-title-13  partner-title">
                <FormattedMessage id="homepage.sec2.title" />
              </div>
              <div className="partners">
                <div className="partner">
                  <img src={img1} alt="Trinity" />
                </div>
                <div className="partner">
                  <img src={img2} alt="A" />
                </div>
                <div className="partner">
                  <img src={img3} alt="I" />
                </div>
                <div className="partner">
                  <img src={img4} alt="CSLB" />
                </div>
                {/* <div className="partner">
                  <img src={img5} alt="Languages4Life" />
                </div> */}
              </div>
            </div>

            {/* CARDS */}
            <ForthcomingCourses
              specialHomepageOutlook
              specialHomepageCardsData={prismicHomePage?.forthcoming_courses?.slice(0, 3)}
              title={{ id: 'forthcomingCourses.title' }}
            />
            {/* CARDS */}
          </div>
        </section>
        <section className="c-section-py home-reviews">
          <div className="container">
            <Script
              src="https://static.elfsight.com/platform/platform.js"
              data-use-service-core
              defer
            ></Script>
            <div class="elfsight-app-eee49a0a-ddff-4bff-86f0-a4d4f59b2b45"></div>
          </div>
        </section>
        <section className="cta-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col">
                <SubscriptionForm />
              </div>
            </div>
          </div>
        </section>
        <section className="third-section">
          <div className="third-section__inner">
            <div className="wrapper container">
              <div className="block_l">
                <h3 className="c-title-34">
                  <FormattedMessage id="homepage.sec3.title" />
                </h3>
              </div>
              <div className="block_r">
                <img src={imgDots} alt="" className="block_r__dots" />
                <div className="benefit">
                  <img src={imgBenefit1} alt="" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">
                      <FormattedMessage id="homepage.sec3.ben1.title" />
                    </h4>
                    <p className="c-text-14">
                      <FormattedMessage id="homepage.sec3.ben1.text" />
                    </p>
                  </div>
                </div>

                <div className="benefit">
                  <img src={imgBenefit2} alt="" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">
                      <FormattedMessage id="homepage.sec3.ben2.title" />
                    </h4>
                    <p className="c-text-14">
                      <FormattedMessage id="homepage.sec3.ben2.text" />
                    </p>
                  </div>
                </div>

                <div className="benefit">
                  <img src={imgBenefit3} alt="" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">
                      <FormattedMessage id="homepage.sec3.ben3.title" />
                    </h4>
                    <p className="c-text-14">
                      <FormattedMessage id="homepage.sec3.ben3.text" />
                    </p>
                  </div>
                </div>

                <div className="benefit">
                  <img src={imgBenefit4} alt="" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">
                      <FormattedMessage id="homepage.sec3.ben4.title" />
                    </h4>
                    <p className="c-text-14">
                      <FormattedMessage id="homepage.sec3.ben4.text" />
                    </p>
                  </div>
                </div>

                <div className="benefit">
                  <img src={imgBenefit5} alt="" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">
                      <FormattedMessage id="homepage.sec3.ben5.title" />
                    </h4>
                    <p className="c-text-14">
                      <FormattedMessage id="homepage.sec3.ben5.text" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section c-section-py">
          <ReviewsSection title={{ id: 'graduates.title' }} />
        </section>

        <section className="fifth-section">
          <div className="wrapper c-section-py">
            <div className="container block_l">
              <div className="row no-gutters">
                <div className="col-xl-12 offset-xl-2">
                  <div className="content">
                    <h2 className="c-title-34">
                      <FormattedMessage
                        id="homepage.sec5.title"
                        values={{
                          accent: (chunks) => <span>{chunks}</span>,
                        }}
                      />
                    </h2>
                    <p className="c-text-18">
                      <FormattedMessage id="homepage.sec5.description" />
                    </p>
                    <div className="block_l__bottom">
                      <div className="item">
                        <h2 className="c-title-42">
                          <FormattedMessage id="homepage.sec5.i1.title" />
                        </h2>
                        <p className="c-text-14">
                          <FormattedMessage id="homepage.sec5.i1.text" />
                        </p>
                      </div>
                      <div className="item">
                        <h2 className="c-title-42">
                          <FormattedMessage id="homepage.sec5.i2.title" />
                        </h2>
                        <p className="c-text-14">
                          <FormattedMessage id="homepage.sec5.i2.text" />
                        </p>
                      </div>
                      <div className="item">
                        <h2 className="c-title-42">
                          <FormattedMessage id="homepage.sec5.i3.title" />
                        </h2>
                        <p className="c-text-14">
                          <FormattedMessage id="homepage.sec5.i3.text" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="block_r">
                    <div className="block_r__img">
                      <img src={imgPhoto_r} alt="imgPhoto_r" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sixth-section c-section-py">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h2 className="c-title-38 text-center mx-auto">
                    <FormattedMessage id="homepage.sec6.title" />
                  </h2>
                </div>
                <div className="c-btn-wrapper">
                  <FormattedMessage id="url.contact.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        <FormattedMessage id="menu.contactUs" />
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                  <FormattedMessage id="url.tefl-iberia-brochure.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red-border">
                        <FormattedMessage id="homepage.sec6.downloadBrochure" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="seventh-section c-section-py">
          <div className="container">
            <h2 className="c-title-34">
              <FormattedMessage id="homepage.sec7.title" />
            </h2>
            <p className="c-text-18">
              <FormattedMessage id="homepage.sec7.description" />
            </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-1">
                <div className="img_l">
                  <img src={imgPhoto_l} alt="imgPhoto_l" />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 offseted">
                <div className="item">
                  <img src={imgAccomodation} alt="imgAccomodation" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="homepage.sec7.i1.title" />
                  </h3>
                  <p className="c-text-14">
                    <FormattedMessage id="homepage.sec7.i1.text" />
                  </p>
                  <div className="item__btns">
                    <FormattedMessage id="url.accommodation">
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--white">
                          <FormattedMessage id="homepage.sec7.i.findOutMore" />
                          <IconArrowRightRed />
                        </Link>
                      )}
                    </FormattedMessage>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-5 col-md-6">
                <div className="item">
                  <img src={imgStudentVisas} alt="imgAccomodation" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="homepage.sec7.i2.title" />
                  </h3>
                  <p className="c-text-14">
                    <FormattedMessage id="homepage.sec7.i2.text" />
                  </p>
                  <div className="item__btns">
                    <FormattedMessage id="url.working-as-a-teacher.student-visas">
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--white">
                          <FormattedMessage id="homepage.sec7.i.findOutMore" />
                          <IconArrowRightRed />
                        </Link>
                      )}
                    </FormattedMessage>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="item">
                  <img src={imgCareersAdvice} alt="imgAccomodation" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="homepage.sec7.i3.title" />
                  </h3>
                  <p className="c-text-14">
                    <FormattedMessage id="homepage.sec7.i3.text" />
                  </p>
                  <div className="item__btns">
                    <FormattedMessage id="url.working-as-a-teacher.careers-service">
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--white">
                          <FormattedMessage id="homepage.sec7.i.findOutMore" />
                          <IconArrowRightRed />
                        </Link>
                      )}
                    </FormattedMessage>
                  </div>
                </div>
                <img className="dots" src={imgDots2} alt="imgDots2" />
              </div>
            </div>
          </div>
        </section>

        <section className="eight-section">
          <div className="container">
            <div className="top">
              <h2 className="c-title-34">
                <FormattedMessage id="homepage.sec8.title" />
              </h2>
              <FormattedMessage id="url.courses.index">
                {(locale) => (
                  <Link to={locale[0]} className="c-btn c-btn--white">
                    {/* <FormattedMessage id="homepage.sec8.seeAllPosts" /> */}
                    See All TEFL Courses
                    <IconArrowRightRed />
                  </Link>
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                {allPrismicArticle.slice(0, 1).map(({ node }) => (
                  <GatsbyLink to={`/blog/${node.uid}`} className="post post--md" key={node.uid}>
                    <div className="post--md__img">
                      {node?.data?.image && (
                        <img src={node.data.image.url} alt={node.data?.title?.text} />
                      )}
                    </div>
                    <div className="post--md__content">
                      <h3 className="c-title-20">{node.data?.title?.text}</h3>
                      <p className="c-text-14">{node.data?.content?.text.slice(0, 275)}...</p>
                      <div className="c-btn c-btn--white">
                        Read more
                        <IconArrowRightRed />
                      </div>
                    </div>
                  </GatsbyLink>
                ))}
              </div>
              <div className="col-lg-7 d-flex">
                {allPrismicArticle.slice(1).map(({ node }) => (
                  <GatsbyLink to={`/blog/${node.uid}`} className="post post--sm" key={node.uid}>
                    <div className="post--sm post--sm__img">
                      {node?.data?.image && (
                        <img src={node.data.image.url} alt={node.data?.title?.text} />
                      )}
                    </div>
                    <div className="post--sm__content">
                      <h3 className="c-title-20">{node.data?.title?.text}</h3>
                      <p className="c-text-14">{node.data?.content?.text.slice(0, 125)}...</p>
                    </div>
                  </GatsbyLink>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="nineth-section c-section-py">
          <div className="container">
            <h2 className="c-title-38">
              <FormattedMessage
                id="homepage.sec9.title"
                values={{
                  accent: (chunk) => <span className="colored no-link"> {chunk}</span>,
                }}
              />
            </h2>
            <p className="c-text-18">
              <FormattedMessage id="homepage.sec9.description" />
            </p>

            <FormattedMessage id="url.apply.index">
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  <FormattedMessage id="menu.applyNow" />
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String!) {
    allPrismicArticle(limit: 4, sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
            image {
              url
            }
          }
        }
      }
    }
    prismicHomePage(lang: { eq: $lang }) {
      data {
        forthcoming_courses {
          title {
            text
          }
          description
          date(formatString: "D MMMM YYYY")
          image {
            url
          }
          link
        }
      }
    }
  }
`;

export default IndexPage;
